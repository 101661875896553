import React, { useReducer, useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from './component/Loading'; // LoadingOverlay component
import { API_URL } from '../config';
import { componentTypes } from './config/componentTypes';
import { safeParseJSON } from './utils/safeParseJSON';
import { useAppData } from './component/AppDataContext';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const initialState = { rows: [] };

function reducer(state, action) {
    switch (action.type) {
        case 'LOAD_PAGE_DATA':
            return {
            ...state,
            rows: action.payload.page_builder_rows.map(row => ({
                id: row.id,
                disabled: row.disabled,
                styles: safeParseJSON(row.styles, {}), // Safely parse styles

                sections: row.page_builder_sections.map(section => ({
                    id: section.id,
                    disabled: section.disabled,
                    styles: safeParseJSON(section.styles, {}), // Safely parse section styles
                    layout_key: section.layout_key,

                    columns: section.page_builder_columns.map(column => ({
                        class: column.class,

                        components: column.page_builder_components.map(component => ({
                            type: component.type,
                            props: safeParseJSON(component.props, {}), // Safely parse component props
                        })),

                        subsections: column.page_builder_subsections.map(subsection => ({
                            id: subsection.id,
                            disabled: subsection.disabled,
                            layout_key: subsection.layout_key,

                            subcolumns: subsection.page_builder_subcolumns.map(subcolumn => ({
                                class: subcolumn.class,

                                subcomponents: subcolumn.page_builder_subcomponents.map(subcomponent => ({
                                    type: subcomponent.type,
                                    props: safeParseJSON(subcomponent.props, {}), // Safely parse subcomponent props
                                })),
                            })),
                        })),
                    })),
                })),
            })),
        };
        default:
            return state;
    }
}

const fetchPageData = async (pageId, subdomain) => {
    const response = await fetch(`${API_URL}/pages-builder/${pageId}/${subdomain}`);
    const data = await response.json();
    console.log(data);
    return data;
};

function PageBuilder({ pageId: propPageId }) {
    const { pageId: paramPageId } = useParams();
    const pageId = propPageId || paramPageId;
    const [{ rows }, dispatch] = useReducer(reducer, initialState);
    const [isLoading, setIsLoading] = useState(true);
    const { subdomain } = useAppData();
    const [currentPage, setCurrentPage] = useState(true);

    useEffect(() => {
        if (pageId) {
            setIsLoading(true);
            fetchPageData(pageId, subdomain).then(pageData => {
                setCurrentPage(pageData);
                dispatch({ type: 'LOAD_PAGE_DATA', payload: pageData });
                setIsLoading(false);
            });
        }
    }, [pageId]);

    // Identify full-screen carousels
    const fullScreenCarousels = rows.flatMap(row =>
        row.sections.flatMap(section =>
            section.columns.flatMap(column =>
                column.components.filter(
                    comp => comp.type === 'carousel' && comp.props.isFullScreen
                )
            )
        )
    );
    const navigate = useNavigate(); // Initialize useNavigate

    return (
        <div>
            {isLoading && <Loading />}

            {/* Render full-screen carousels */}
            {fullScreenCarousels.map((carouselComp, idx) => (
                <div key={idx} className="carousel-full-screen">
                    {React.createElement(componentTypes[carouselComp.type], {
                        ...carouselComp.props,
                    })}
                </div>
            ))}

            {/* Render rows */}
            {rows.map((row, rowIndex) => (
                <React.Fragment key={row.id}>
                    {row.disabled !== 1 && (
                        <div
                            className="row-wrap"
                            style={row.styles || {}} // Apply row styles
                        >
                            {/* Render sections */}
                            {row.sections.map((section, sectionIndex) => (
                                <React.Fragment key={section.id}>
                                    {section.disabled !== 1 && (
                                        <div
                                            className="section-wrap">                                
                                            <div className="container">
                                                <div className="row">
                                                    {/* Render columns */}
                                                    {section.columns.map(
                                                        (column, columnIndex) => (
                                                            <div
                                                                key={columnIndex}
                                                                className={`${column.class} p-3`}
                                                            >
                                                                {/* Render subsections */}
                                                                {column.subsections.map(
                                                                    (
                                                                        subsection,
                                                                        subIndex
                                                                    ) => (
                                                                        <React.Fragment
                                                                            key={
                                                                                subsection.id
                                                                            }
                                                                        >
                                                                            {subsection.disabled !==
                                                                                1 && (
                                                                                <div className="row">
                                                                                    {subsection.subcolumns.map(
                                                                                        (
                                                                                            subcolumn,
                                                                                            subcolIndex
                                                                                        ) => (
                                                                                            <div
                                                                                                key={
                                                                                                    subcolIndex
                                                                                                }
                                                                                                className={`${subcolumn.class} p-3`}
                                                                                            >
                                                                                                {/* Render subcomponents */}
                                                                                                {subcolumn.subcomponents.map(
                                                                                                    (
                                                                                                        subcomp,
                                                                                                        subcompIndex
                                                                                                    ) => (
                                                                                                        <div
                                                                                                            key={
                                                                                                                subcompIndex
                                                                                                            }
                                                                                                        >
                                                                                                            {React.createElement(
                                                                                                                componentTypes[
                                                                                                                    subcomp.type
                                                                                                                ],
                                                                                                                {
                                                                                                                    ...subcomp.props,
                                                                                                                }
                                                                                                            )}
                                                                                                        </div>
                                                                                                    )
                                                                                                )}
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </React.Fragment>
                                                                    )
                                                                )}
                                                                {/* Render components */}
                                                                {column.components.map(
                                                                    (
                                                                        comp,
                                                                        compIndex
                                                                    ) => {
                                                                        if (
                                                                            comp.type ===
                                                                                'carousel' &&
                                                                            comp.props
                                                                                .isFullScreen
                                                                        )
                                                                            return null; // Skip full-screen carousels here
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    compIndex
                                                                                }
                                                                            >
                                                                                {React.createElement(
                                                                                    componentTypes[
                                                                                        comp.type
                                                                                    ],
                                                                                    {
                                                                                        ...comp.props,
                                                                                    }
                                                                                )}
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
}

export default PageBuilder;
