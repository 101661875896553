import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ResponsiveIframe = ({ url }) => {
  // Define view types and their corresponding dimensions
  const views = {
    mobile: { width: '375px', height: '667px' },  // iPhone X
    tablet: { width: '768px', height: '1024px' }, // iPad
    web: { width: '100%', height: '1200px' },      // Full width
  };

  // State to keep track of the current view
  const [currentView, setCurrentView] = useState('web');

  // Handler to change the view
  const handleViewChange = (view) => {
    setCurrentView(view);
  };

  // Get the dimensions based on the current view
  const { width, height } = views[currentView];

  // Styles
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
  };

  const buttonContainerStyle = {
    marginBottom: '20px',
  };

  const buttonStyle = (active) => ({
    padding: '10px 20px',
    margin: '0 10px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: active ? '#007BFF' : '#CCCCCC',
    color: '#fff',
    cursor: 'pointer',
  });

  const iframeStyle = {
    width: width,
    height: height,
    border: '1px solid #ccc',
    borderRadius: '8px',
  };

  return (
    <div style={containerStyle}>
      <div style={buttonContainerStyle}>
        <button
          style={buttonStyle(currentView === 'mobile')}
          onClick={() => handleViewChange('mobile')}
        >
          Mobile
        </button>
        <button
          style={buttonStyle(currentView === 'tablet')}
          onClick={() => handleViewChange('tablet')}
        >
          Tablet
        </button>
        <button
          style={buttonStyle(currentView === 'web')}
          onClick={() => handleViewChange('web')}
        >
          Web
        </button>
      </div>
      <iframe
        src={url}
        title="Responsive Iframe"
        style={iframeStyle}
        allowFullScreen
      ></iframe>
    </div>
  );
};

// PropTypes for validation
ResponsiveIframe.propTypes = {
  url: PropTypes.string.isRequired,
};

// Default Props (optional)
ResponsiveIframe.defaultProps = {
  url: 'https://www.example.com',
};

export default ResponsiveIframe;
