import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Loading from './component/Loading'; // LoadingOverlay component
import { useAppData } from './component/AppDataContext';
import ResponsiveIframe from './utils/ResponsiveIframe';

function PreviewPages() {
    const { slug, pageId } = useParams(); // Extract both slug and pageId from URL
    const [isLoading, setIsLoading] = useState(false);
    const { subdomain } = useAppData(); // Get the subdomain from context
    const navigate = useNavigate(); // Use navigate for redirection

    // Construct the URL for the iframe
    const url = `/${slug}`;

    const handleExitPreview = () => {
        // Redirect to /page-builder/{pageId}
        navigate(`/page-builder/${pageId}`);
    };

    return (
        <div>
            {isLoading && <Loading />}

            {/* Header Section with Exit Preview Button */}
            <div className="page-builder-header d-flex justify-content-between align-items-center p-3 bg-light">
                <h2>Preview Page</h2>
                <button className="btn btn-primary" onClick={handleExitPreview}>
                    Exit Preview
                </button>
            </div>

            {/* ResponsiveIframe */}
            <ResponsiveIframe url={url} />
        </div>
    );
}

export default PreviewPages;
